import React from 'react'
import clsx from 'clsx'
import Link from '../../../Link'
import StyledIcon from '../../StyledIcon'
import { slideControl, ctaAlignRight, variantEmail, variantPlayButton } from './IconBtn.module.scss'

const IconBtn = ({ color, size, href, cta, variant, state, label, ...props }) => {
	let btnVariant = variantType => {
		// if round, have inner wrapper align items center, otherwise don't

		switch (variantType) {
			case 'playNow':
				return (
					<button type="button" onClick={props.onClick} className={variantPlayButton}>
						<span className="d-flex align-items-center">
							<StyledIcon
								round
								iconColor="white"
								color="orange"
								size="large"
								name="play"
							/>

							<span className={clsx(ctaAlignRight, 'article-meta')}>
								Play Now
							</span>
						</span>
					</button>
				)

			case 'Back':
				return (
					<Link to={href || '/contact'} state={state}>
						<span className="d-flex">
							<StyledIcon iconColor="orange" size="small" name="left" />
							<span className={clsx(ctaAlignRight, 'article-meta')}>
								{cta || 'Back to Previous Results'}
							</span>
						</span>
					</Link>
				)
			case 'SendEmail':
				return (
					<a className={variantEmail} href={href || '/contact'}>
						<span className="d-flex align-items-center">
							<StyledIcon iconColor="dark" size="small" name="email" />
							<span className={clsx(ctaAlignRight, 'article-meta')}>
								Send Email
							</span>
						</span>
					</a>
				)
			case 'IconRightOutline':
				return (
					<button
						role='button'
						onClick={props.onClick}
						className={`nextBtn ` + slideControl}
						disabled={props.disabled}
						aria-label="Next"
						tabIndex={0}
					>
						<StyledIcon
							outline
							round
							name="right"
							iconColor="grey"
							color="light"
							size="large"
						/>
					</button>
				)
			case 'IconLeftOutline':
				return (
					<button
						role='button'
						onClick={props.onClick}
						className={`prevBtn ` + slideControl}
						disabled={props.disabled}
						aria-label="Previous"
						tabIndex={0}
					>
						<StyledIcon
							outline
							round
							name="left"
							iconColor="grey"
							color="light"
							size="large"
						/>
					</button>
				)
			case 'IconRight':
				return (
					<Link to={href || ''} state={state} aria-label={label}>
						<StyledIcon
							round
							name="right"
							iconColor="grey"
							color="light"
							size="large"
						/>
					</Link>
				)
			case 'ViewProfile':
				return (
					<Link
						to={href || ''}
						className="d-flex align-items-center"
						state={state}
					>
						<span className="article-meta me-2">View Profile</span>
						<StyledIcon name="right" color="light" size="small" />
					</Link>
				)

			case 'Apple':
				return (
					<Link
						to={href || ''}
						className={clsx(
							'btn-label btn btn-outline-dark btn-sm mt-3',
							href ? '' : 'disabled'
						)}
					>
						<StyledIcon btn name="apple" iconColor="black" size="small" />
						{cta || ''}
					</Link>
				)
			case 'Spotify':
				return (
					<Link
						to={href || ''}
						className={clsx(
							'btn-label btn btn-outline-dark btn-sm mt-3',
							href ? '' : 'disabled'
						)}
						state={state}
					>
						<StyledIcon btn name="spotify" iconColor="black" size="small" />
						{cta || ''}
					</Link>
				)
			case 'Google':
				return (
					<Link
						to={href || ''}
						className={clsx(
							'btn-label btn btn-outline-dark btn-sm mt-3',
							href ? '' : 'disabled'
						)}
						state={state}
					>
						<StyledIcon btn name="googlePlay" iconColor="black" size="small" />
						{cta || ''}
					</Link>
				)
		}
	}

	return <>{btnVariant(variant)}</>
}

export default IconBtn
