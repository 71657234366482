import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from '../Button'
import {
	heroWrapper,
	headingWrapper,
	descriptionWrapper,
	heroImageWrapper,
	heroLangSelect
} from './HeroRight.module.scss'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import LanguageSelector from "../LanguageSelector";

const HeroRight = ({ heading, content, langSelect, langLocale, image, ctaLink, ctaLabel, ctaClick }) => {
	const imageData = getImage(image)
	return (
		<>
			<Container className={heroWrapper} fluid="xl">
				<Row className="align-items-center">
					<Col xs={{ span: 12, order: 2 }} md={{ span: 4, order: 1 }}>
						<div className={heroLangSelect}>
							<LanguageSelector translations={langSelect} locale={langLocale} />
						</div>

						{heading ? (
							<div className={headingWrapper}>
								<h1 className="title--xl">{heading}</h1>
							</div>
						) : null}

						{content ? (
							<div
								className={`editor-section ` + descriptionWrapper}
								dangerouslySetInnerHTML={{ __html: content }}
							/>
						) : null}

						{ctaLink && ctaLabel ? (
							<Button buttonText={ctaLabel} to={ctaLink} onClick={ctaClick} />
						) : null}
					</Col>
					<Col
						xs={{ span: 12, order: 1 }}
						md={{ span: 7, offset: 1, order: 2 }}
					>
						{imageData ? (
							<div className={heroImageWrapper}>
								<GatsbyImage
									image={imageData}
									className="img-fluid"
									alt={heading}
								/>
							</div>
						) : null}
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default HeroRight
