import React from 'react'
import PeopleListQuery from './query/PeopleListQuery'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PeopleCard from './PeopleCard'
import { gridViewWrapper } from './PeopleGridView.module.scss'

const PeopleGridView = ({ people, service, leaders, ...props }) => {
  let peopleData = people ?? PeopleListQuery(service)
  let leaderIds = []

  if (service && leaders) {
    const leaderList = []
    const removeList = []
    leaderIds = leaders.map(obj => obj.id)
    peopleData.map((people, index) => {
      if (leaderIds.includes(people.people.id)) {
        removeList.push(index)
        if (leaderIds.length > 1) {
          people.people.coLeader = true
        } else {
          people.people.leader = true
        }
        leaderList.push(people)
      }
    })

    // clean up array (remove leaders)
    for (let i = removeList.length - 1; i >= 0; i--) {
      peopleData.splice(removeList[i], 1)
    }

    // combine the data sets
    peopleData = [...leaderList, ...peopleData]
  }

  if (!service && !people?.length) {
    return (<p className="pt-5" role="status">No results found.</p>)
  }

  return (
    <>
      <p role="status" className="sr-only">{people?.length} results found.</p>
    
      <Row className={gridViewWrapper}>
        {peopleData.map((v, i) => {
          let people = v.people
          let leader = leaderIds.length === 1 && leaderIds.includes(people.id)
          let coLeader = leaderIds.length > 1 && leaderIds.includes(people.id)

          return (
            <Col xs={6} sm={4} md={4} xl={3} key={i} className="d-flex align-items-stretch">
              <PeopleCard people={people} leader={leader} coLeader={coLeader} path={props.path} />
            </Col>
          )
        })}
      </Row>
    </>
  )
}

export default PeopleGridView
