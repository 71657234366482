import React from 'react'
import { Row, Col } from 'react-bootstrap'
import * as styles from './OverviewText.module.scss'

const OverviewText = ({ ...props }) => {
	const { title, text } = props
	return (
		<section className={styles.overviewText}>
			<Row>
				<Col md={4}>
					<h2>{title}</h2>
				</Col>
				<Col md={8}>
					<div
						className="editor-section"
						dangerouslySetInnerHTML={{ __html: text }}
					/>
				</Col>
			</Row>
		</section>
	)
}

export default OverviewText
