import { useStaticQuery, graphql } from 'gatsby'

export const useEventsByService = (serviceSlug) => {
  const { allWpPost } = useStaticQuery(
    graphql`
        query AllEventsForService {
            allWpPost(
                sort: { fields: [eventInformation___startDate], order: ASC }
                filter: {resourceTypes: {nodes: {elemMatch: {slug: {eq: "events"}}}}}
            ) {
                edges {
                    event: node {
                        id
                        uri
                        date(formatString: "MMMM DD, YYYY")
                        title
                        categories {
                            nodes {
                                uri
                                name
                            }
                        }
                        resourceTypes {
                            nodes {
                                slug
                                name
                            }
                        }
                        featuredImage {
                            node {
                                altText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            layout: CONSTRAINED,
                                            aspectRatio: 1.59,
                                            width: 550
                                        )
                                    }
                                }
                            }
                        }
                        eventInformation {
                            startDate
                            endDate
                            time
                        }
                        resourceInformation {
                            service {
                                ... on WpService {
                                    slug
                                    title
                                    uri
                                    serviceTypes {
                                        nodes {
                                            slug
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `
  )
    const events = filterByAuthor(allWpPost?.edges, serviceSlug)
    return organizeByDate(events)
}

const organizeByDate = (events) => {
    let now = new Date();
    now = `${now.getFullYear()}-${("0" + (now.getMonth() + 1)).slice(-2)}-${("0" + now.getDate()).slice(-2)}`

    let pastEvents = events.filter(({event}) => {
        return event?.eventInformation?.startDate < now
    })
    let upcomingEvents = events.filter(({event}) => {
        return event?.eventInformation?.startDate >= now
    })

    pastEvents.reverse()

    let eventList = [...upcomingEvents, ...pastEvents]
    return eventList.map(({event}) => {
        return event
    })

}

const filterByAuthor = (events, serviceSlug) => {
    const checkId = obj => obj.slug === serviceSlug
    return events.filter(({ event }) => {
        return (
          event?.resourceInformation?.service?.some(checkId)
        )
    })
}