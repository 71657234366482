import { useStaticQuery, graphql } from 'gatsby'

const PeopleListQuery = service => {
	const peopleData = useStaticQuery(graphql`
		query PeopleListQuery {
			allWpPeople(
				sort: { fields: [peopleInformation___lastName], order: ASC }
				filter: {locale: {locale: {eq: "en_US"}}}
			) {
				edges {
					people: node {
						id
						uri
						slug
						title
						content
						excerpt
						jobTitles {
							nodes {
								id
								name
							}
						}
						peopleInformation {
							email
							fax
							fieldGroupName
							firstName
							lastName
							linkedin
							mobile
							phone
							twitter
							proOffice {
								... on WpOffice {
									id
									title
									slug
									officeInformation {
										proOfficeCity
										proOfficeState
										proOfficeStreet
										proOfficeZipcode
									}
								}
							}
							proService {
								... on WpService {
									slug
									title
								}
							}
						}
						featuredImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											layout: CONSTRAINED,
											aspectRatio: 0.94,
											width: 600
										)
									}
								}
							}
						}
						locale {
							id
							locale
						}
						translations {
							id
							locale
							href
						}
						contentType {
							node {
								name
							}
						}
					}
				}
			}
		}
	`)
	return applyFilters(peopleData, service)
}

const applyFilters = (query, service) => {
	let queryData = query?.allWpPeople?.edges
	let data = []

	queryData?.map((people, i) => {
		let showType = true

		if (service) {
			showType = false
			people?.people?.peopleInformation?.proService?.map(serviceData => {
				if (serviceData?.slug == service) showType = true
			})
		}
		if (showType) data.push(people)
	})
	return data
}

export default PeopleListQuery
