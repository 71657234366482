import { useStaticQuery, graphql } from 'gatsby'

export const useTestimoinalsByService = (serviceSlug) => {
  const { allWpTestimonial } = useStaticQuery(
    graphql`
        query AllTestimonialsForService {
            allWpTestimonial {
                edges {
                    testimonial: node {
                        id
                        content
                        testimonialInformation {
                            author
                            jobtitle
                            size
                            testimonialfull
                            variant
                            proService {
                                ... on WpService {
                                    slug
                                    title
                                }
                            }
                        }
                    }
                }
            }
        }
    `
  )
  return filterByService(allWpTestimonial?.edges, serviceSlug)
}

const filterByService = (testimonials, serviceSlug) => {
  const checkId = obj => obj.slug === serviceSlug
  return testimonials.filter(({ testimonial }) => {
    return (
      testimonial?.testimonialInformation?.proService?.some(checkId)
    )
  })
}