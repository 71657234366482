import { useStaticQuery, graphql } from 'gatsby'

export const useResourcesByService = (serviceSlug, isCategory = false) => {
  const { allWpPost } = useStaticQuery(
    graphql`
        query AllPostsForService {
            allWpPost(
                sort: { fields: [date], order: DESC }
                filter: {
                    locale: {id: {eq: "en_US"}},
                    resourceTypes: {nodes: {elemMatch: {slug: {ne: "events"}}}}
                }
            ) {
                edges {
                    resource: node {
                        id
                        uri
                        date(formatString: "MMMM DD, YYYY")
                        title
                        categories {
                            nodes {
                                slug
                                uri
                                name
                            }
                        }
                        resourceTypes {
                            nodes {
                                slug
                                name
                            }
                        }
                        featuredImage {
                            node {
                                altText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            layout: CONSTRAINED,
                                            aspectRatio: 1.59,
                                            width: 550
                                        )
                                    }
                                }
                            }
                        }
                        relatedImage: featuredImage {
                            node {
                                altText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            aspectRatio: 0.75
                                            layout: CONSTRAINED
                                            width: 485
                                        )
                                    }
                                }
                            }
                        }
                        resourceInformation {
                            service {
                                ... on WpService {
                                    slug
                                    title
                                    uri
                                    serviceTypes {
                                        nodes {
                                            slug
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `
  )
  return isCategory ? filterByCategory(allWpPost?.edges, serviceSlug) : filterByService(allWpPost?.edges, serviceSlug)
}

const filterByService = (resources, serviceSlug) => {
  const checkId = obj => obj.slug === serviceSlug
  return resources.filter(({ resource }) => {
    return (
      resource?.resourceInformation?.service?.some(checkId)
    )
  })
}

const filterByCategory = (resources, categorySlug) => {
    const checkId = obj => obj.slug === categorySlug
    return resources.filter(({ resource }) => {
        return (
          resource?.categories?.nodes?.some(checkId)
        )
    })
}
